/**
 * Cookie Preferences handler for Google Tagmanager v0.5
 * (c) 2021 Chatter Communications
 * Author: Rick Harrrison rick@welovechatter.com
 * 
 * This is a relatively generic handler for asking for people's permissions to use cookies on a site. It's design
 * agnostic - if just expects the following items to work:
 * 
 * 1/ A master container for the cookie popover with the ID #cookie-notice-container, with an inline style of display: none;
 * 2/ Within the master container 2 sibling sub containers with the IDs #cookie-notice && #cookie-settings
 * 
 */
/* Types of cookie:

https://gdpr.eu/cookies/
Strictly necessary cookies â€” These cookies are essential for you to browse the website and use its features, such as accessing secure areas of the site. Cookies that allow web shops to hold your items in your cart while you are shopping online are an example of strictly necessary cookies. These cookies will generally be first-party session cookies. While it is not required to obtain consent for these cookies, what they do and why they are necessary should be explained to the user.
Preferences cookies â€” Also known as â€œfunctionality cookies,â€ these cookies allow a website to remember choices you have made in the past, like what language you prefer, what region you would like weather reports for, or what your user name and password are so you can automatically log in.
Statistics cookies â€” Also known as â€œperformance cookies,â€ these cookies collect information about how you use a website, like which pages you visited and which links you clicked on. None of this information can be used to identify you. It is all aggregated and, therefore, anonymized. Their sole purpose is to improve website functions. This includes cookies from third-party analytics services as long as the cookies are for the exclusive use of the owner of the website visited.
Marketing cookies â€” These cookies track your online activity to help advertisers deliver more relevant advertising or to limit how many times you see an ad. These cookies can share that information with other organizations or advertisers. These are persistent cookies and almost always of third-party provenance.
*/

var gtmCookieAcceptance = {

  settingsAccepted: false,

  gdprCookieSettings: {
    preferences: false,
    statistic: false,
    marketing: false
  },

  minimisedPopoverHeight: 0,
  totalPopoverHeight: 0,

  listenerAdded: false,

  init: function () {


    var tmp = localStorage.getItem('gdprCookieSettings');
    if (tmp == null || tmp == '') {
      this.gdprCookieSettings = {
        preferences: false,
        statistic: false,
        marketing: false
      };
      this.saveCookieSettings();

    } else {
      this.gdprCookieSettings = JSON.parse(tmp);
    }

    this.settingsAccepted = localStorage.getItem('gdprCookieSettingsAccepted');
    if (this.settingsAccepted == null) {
      this.settingsAccepted = false;
    }

    // add/update data-layer variables.
    this.updateDataLayer();

    // delay everything else until dom-ready
    document.addEventListener("DOMContentLoaded", function () { // Handler when the DOM is fully loaded
      if (! gtmCookieAcceptance.settingsAccepted) {
        gtmCookieAcceptance.showCookiePopover();

        var actionChangeSettings = document.querySelector('#cookie-notice-container .js-cookie-change-settings')
        if (actionChangeSettings) {
          actionChangeSettings.addEventListener('click', function () {
            gtmCookieAcceptance.showCookiePopover();
          });
        }


      }

      // i haven't the faintest idea why this needs a timout to work - something else in the app
      // js must be conflicting with it, as if it run it here and now and debug it appears to be checking
      // the checkboxes, but when you inspect the page they're unchecked....No-one's going to click into the
      // more settings link less than 1 second from the DOM being ready tho (and should be less than 1 second
      // from the point it's visible and interactive).
      window.setTimeout(gtmCookieAcceptance.defaultCookieCheckboxes, 1000);

      var cookieSettingsLink = document.querySelector('.js-cookie-view-settings');

      if (cookieSettingsLink) {

        // i'm adding the click listener to body and checking the target - just attaching the event handlerr
        // to the actual like fails - SOMETHING appears to be preventDefaulting and stopping it running, adding
        // the listener at BODY level sees to work tho.
        document.querySelector('BODY').addEventListener('click', function (event) {
          if (event.target.classList.contains('js-cookie-view-settings') || (event.target.innerText && event.target.innerText.toLowerCase() == 'cookie settings')) {

            event.preventDefault();

            var copyToHideOnEdit = document.querySelector('.js-cookie-hide-on-edit');
            if (copyToHideOnEdit) {
              copyToHideOnEdit.style.display = 'none';
            }

            var masterCookiePopoverContainer = document.querySelector('#cookie-notice-container');
            masterCookiePopoverContainer.classList.toggle('show-settings');
            if (masterCookiePopoverContainer.classList.contains('show-settings')) {
              const height = document.querySelector('#cookie-notice-container').offsetHeight;
              masterCookiePopoverContainer.style.transform = `translateY(-${height}px)`;

              document.querySelector('.js-cookie-accept-all').style.display = 'none';
              document.querySelector('.js-cookie-save-settings').style.display = 'inline-block';
              gtmCookieAcceptance.listenForCookieChanges();
            } else {
              masterCookiePopoverContainer.style.transform = 'translateY(0)';

              document.querySelector('.js-cookie-save-settings').style.display = 'none';
              // document.querySelector('.js-cookie-policy').style.display = 'inline-block';
              document.querySelector('.js-cookie-accept-all').style.display = 'inline-block';

            }
          }
          if (event.target.classList.contains('js-cookie-save-settings')) {
            event.preventDefault();
            gtmCookieAcceptance.acceptSettings();
          }

          if (event.target.classList.contains('js-cookie-accept-all')) {
            event.preventDefault();
            gtmCookieAcceptance.allowPreferencesCookies();
            gtmCookieAcceptance.allowStatisticsCookies();
            gtmCookieAcceptance.allowMarketingCookies();
            gtmCookieAcceptance.acceptSettings();
            gtmCookieAcceptance.hideCookiePopover();
          }
        });
      } else {
        console.log('GTM-COOKIE-ACCEPTANCE - ERROR: COULDNT FIND VIEW-SETTINGS LINK TO ATTACH TO.');
      }
    });

  },

  defaultCookieCheckboxes: function () {
    if (gtmCookieAcceptance.gdprCookieSettings.preferences == true) {
      var ckbox = document.querySelector('#cookies-preferences');
      if (ckbox) {
        ckbox.checked = true;
        ckbox.parentElement.querySelector('svg').classList.add('toggle--on');
        var dynamicMsg = ckbox.parentElement.querySelector('#cookies-preferences-state');
        if (dynamicMsg) {
          dynamicMsg.innerHTML = dynamicMsg.dataset.enabledMsg;
        }
      }
    } else {
      var dynamicMsg = document.querySelector('#cookies-preferences-state');
      if (dynamicMsg) {
        dynamicMsg.innerHTML = dynamicMsg.dataset.disabledMsg;
      }
    }

    if (gtmCookieAcceptance.gdprCookieSettings.statistic == true) {
      var ckbox = document.querySelector('#cookies-statistic');
      if (ckbox) {
        ckbox.checked = true;
        ckbox.parentElement.querySelector('svg').classList.add('toggle--on');
        var dynamicMsg = ckbox.parentElement.querySelector('#cookies-statistic-state');
        if (dynamicMsg) {
          dynamicMsg.innerHTML = dynamicMsg.dataset.enabledMsg;
        }

      }
    } else {
      var dynamicMsg = document.querySelector('#cookies-statistic-state');
      if (dynamicMsg) {
        dynamicMsg.innerHTML = dynamicMsg.dataset.disabledMsg;
      }
    }
    if (gtmCookieAcceptance.gdprCookieSettings.marketing == true) {
      var ckbox = document.querySelector('#cookies-marketing');
      if (ckbox) {
        ckbox.checked = true;
        ckbox.parentElement.querySelector('svg').classList.add('toggle--on');
        var dynamicMsg = ckbox.parentElement.querySelector('#cookies-marketing-state');
        if (dynamicMsg) {
          dynamicMsg.innerHTML = dynamicMsg.dataset.enabledMsg;
        }
      }
    } else {
      var dynamicMsg = document.querySelector('#cookies-marketing-state');
      if (dynamicMsg) {
        dynamicMsg.innerHTML = dynamicMsg.dataset.disabledMsg;
      }
    }


  },

  allowPreferencesCookies: function () {
    this.gdprCookieSettings.preferences = true;
    this.updateDataLayer();
    window.dataLayer.push({'event': 'cookie-acceptance-allow-preferences'});
    window.dataLayer.push({'event': 'cookie-acceptance-changed'});
    this.saveCookieSettings();
    var dynamicMsg = document.querySelector('#cookies-preferences-state');
    if (dynamicMsg) {
      dynamicMsg.innerHTML = dynamicMsg.dataset.enabledMsg;
    }
  },

  allowStatisticsCookies: function () {
    this.gdprCookieSettings.statistic = true;
    this.updateDataLayer();
    window.dataLayer.push({'event': 'cookie-acceptance-allow-statistics'});
    window.dataLayer.push({'event': 'cookie-acceptance-changed'});
    this.saveCookieSettings();
    var dynamicMsg = document.querySelector('#cookies-statistic-state');
    if (dynamicMsg) {
      dynamicMsg.innerHTML = dynamicMsg.dataset.enabledMsg;
    }
  },

  allowMarketingCookies: function () {
    this.gdprCookieSettings.marketing = true;
    this.updateDataLayer();
    window.dataLayer.push({'event': 'cookie-acceptance-allow-marketing'});
    window.dataLayer.push({'event': 'cookie-acceptance-changed'});
    this.saveCookieSettings();
    var dynamicMsg = document.querySelector('#cookies-marketing-state');
    if (dynamicMsg) {
      dynamicMsg.innerHTML = dynamicMsg.dataset.enabledMsg;
    }

  },

  denyPreferencesCookies: function () {
    this.gdprCookieSettings.preferences = false;
    this.updateDataLayer();
    window.dataLayer.push({'event': 'cookie-acceptance-deny-preferences'});
    window.dataLayer.push({'event': 'cookie-acceptance-changed'});
    this.saveCookieSettings();
    var dynamicMsg = document.querySelector('#cookies-preferences-state');
    if (dynamicMsg) {
      dynamicMsg.innerHTML = dynamicMsg.dataset.disabledMsg;
    }
  },

  denyStatisticsCookies: function () {
    this.gdprCookieSettings.statistic = false;
    this.updateDataLayer();
    window.dataLayer.push({'event': 'cookie-acceptance-deny-statistics'});
    window.dataLayer.push({'event': 'cookie-acceptance-changed'});
    this.saveCookieSettings();
    var dynamicMsg = document.querySelector('#cookies-statistic-state');
    if (dynamicMsg) {
      dynamicMsg.innerHTML = dynamicMsg.dataset.disabledMsg;
    }
  },

  denyMarketingCookies: function () {
    this.gdprCookieSettings.marketing = false;
    this.updateDataLayer();
    window.dataLayer.push({'event': 'cookie-acceptance-deny-marketing'});
    window.dataLayer.push({'event': 'cookie-acceptance-changed'});
    this.saveCookieSettings();
    var dynamicMsg = document.querySelector('#cookies-marketing-state');
    if (dynamicMsg) {
      dynamicMsg.innerHTML = dynamicMsg.dataset.disabledMsg;
    }
  },

  saveCookieSettings: function () {
    localStorage.setItem('gdprCookieSettings', JSON.stringify(gtmCookieAcceptance.gdprCookieSettings));
  },

  acceptSettings: function () {
    gtmCookieAcceptance.settingsAccepted = true;
    localStorage.setItem('gdprCookieSettingsAccepted', true);
    gtmCookieAcceptance.hideCookiePopover();

  },

  listenForCookieChanges: function () {
    if (! gtmCookieAcceptance.listenerAdded) {
      document.querySelectorAll('#cookie-settings input[type="checkbox"]').forEach(function (elem) {

        elem.addEventListener('change', function () {
          this.parentElement.querySelector('label svg').classList.toggle('toggle--on');
          switch (this.id) {
            case 'cookies-statistic':
              if (this.checked == true) {
                gtmCookieAcceptance.allowStatisticsCookies();
              } else {
                gtmCookieAcceptance.denyStatisticsCookies();
              }
              break;
            case 'cookies-marketing':
              if (this.checked == true) {
                gtmCookieAcceptance.allowMarketingCookies();
              } else {
                gtmCookieAcceptance.denyMarketingCookies();
              }
              break;

            case 'cookies-preferences':
              if (this.checked == true) {
                gtmCookieAcceptance.allowPreferencesCookies();
              } else {
                gtmCookieAcceptance.denyPreferencesCookies();
              }
              break;

            default:
              console.log('error: unknown cookie checkbox changed.');
              break;
          }
        });
      });
      gtmCookieAcceptance.listenerAdded = true;
    }

  },

  showCookiePopover() {
    // something on the page causes a redraw after the cookie code would normally run, so lets
    // add a 1/2 second delay before showing and sizing it.
    setTimeout(function () {
      document.querySelector('#cookie-notice-container').style.display = 'block';
      gtmCookieAcceptance.sizeCookiePopover();
      window.addEventListener('resize', gtmCookieAcceptance.sizeCookiePopover);
    }, 1500);
  },

  sizeCookiePopover() {
    const container = document.querySelector('#cookie-notice-container');

    this.minimisedPopoverHeight = container.offsetHeight;
    this.totalPopoverHeight = document.querySelector('#cookie-notice').offsetHeight;

    let height = this.totalPopoverHeight;
    if (container.classList.contains('show-settings')) {
      height = this.minimisedPopoverHeight;
    }

    container.style.transform = `translateY(-${height}px)`;
  },

  hideCookiePopover() { // reset the sizes of the cookie popover before hiding it.
    document.querySelector('#cookie-notice-container').classList.remove('show-settings');
    gtmCookieAcceptance.sizeCookiePopover();
    document.querySelector('#cookie-notice-container').style.display = 'none';
  },

  updateDataLayer() {
    if (typeof(window.dataLayer) == 'undefined') {
      window.dataLayer = [{
          'TagmanCookiePrefs_allowed': ''
        }];
    }
    var allowedCookies = ['strictly_necessary'];
    if (this.gdprCookieSettings.preferences) {
      allowedCookies.push('preferences');
    }
    if (this.gdprCookieSettings.statistic) {
      allowedCookies.push('statistic');
    }
    if (this.gdprCookieSettings.marketing) {
      allowedCookies.push('marketing');
    }
    window.dataLayer.push({'TagmanCookiePrefs_allowed': allowedCookies.join()});
  }

}


// simple polyfill for forEach in older browsers:
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// initialise google tagmanager cookie acceptance.
gtmCookieAcceptance.init();
